<template>
    <Loading :isLoading="isLoading" />
    <div class="flex ml-5 mt-5 cursor-pointer" @click="redirect_history()" >
        <img src="@/assets/back-arrow.svg">Atrás
    </div>
    <div class="bg-white m-0 px-5 md:px-3 md:m-5 ">
        <div class="grid grid-cols-1 md:grid-cols-2 px-0 md:px-0 lg:px-20 mt-10">

            <div class="w-full md:w-4/5 md:mx-auto mt-5">
                <p class="text-3xl font-bold roboto-regular mb-4">{{ questionTitle }}</p>
                <IssuanceLaborInformationComponent ref="ComponentsLaborInformationRef" :form_visibility="formVisibility" :view_class_view="view_class_view" :validations="rulevalidations" />

                <div class="w-full flex justify-center">
                   <input type="button" id="button_next" :disabled="!isActive" :class="{'button-disabled cursor-default': !isActive, 'bg-mustardDark cursor-pointer': isActive}" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="next()" value="Continuar">        
                </div>
            </div>
            <div class="w-full bg-white hidden md:block md:py-14">
                <img src="@/assets/Cuenta/vidaSimple/labor_data_of_the_contractor.svg" :alt="questionTitle" class="mx-auto" >
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { helpers, requiredIf, minLength, maxLength } from '@vuelidate/validators';

import Loading from '@/components/Loading/VueLoading.vue';
import { availableFormStorage, getLocalStorage, createLocalStorage } from '@/helpers/storage.js';
import Quote from '@/classes/Quotation.js';


export default {
    name: "LaborInformation",
    setup(){
        const router = useRouter();
        const route = useRoute();
        const isLoading = ref(true);
        const questionTitle = ref("Datos Laborales");
        const ComponentsLaborInformationRef = ref(null);
        const category = ref("");
        const quoteClass = ref(null);
        const formVisibility = ref({company: true,occupation: true,anual_income: true});

        let view_class_view = {
            title: "hidden",
            class_inputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
            div_container:"grid grid-cols-1 sm:grid-cols-1 sm:gap-2",
        };

        const rulevalidations = {
            company: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true)),
                minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres` , minLength(2)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 50 caracteres` , maxLength(50))
            },
            occupation: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true)),
                minLength: helpers.withMessage(`El campo debe contener mínimo 5 caracteres` , minLength(5)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 50 caracteres` , maxLength(50))
            },
            annual_income: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true)),
                minAnualIncome: helpers.withMessage('El ingreso anual mínimo deber ser $15,000.00', function(value){
                    const numeroEntero = parseInt(value.replace(/[^\d.-]/g, ""));
                    return !(numeroEntero < 15000)
                })

            }
        };
        
        let isActive = ref(true);

        const type_client = {
            "Para Adicional": configuration_for_aditional,
            "Para socio":  get_user_information
        }

        onMounted(async () => {
            quoteClass.value = new Quote();
            category.value = getCategory(false);

            const quote = quoteClass.value.get(category.value);
            type_client[quote.is_additional_insured]();
            if(quote.is_additional_insured == "Para socio") formVisibility.value = {company: true,occupation: false,anual_income: true};
            
            isLoading.value = false;
            isActive.value = true;
            loadFormStorage();
        });
        
        const loadFormStorage = () => {
            try{
                if(!availableFormStorage('issuance-labor-information')) return;
                
                const data = getLocalStorage('issuance-labor-information');
                set_data_component(data);

            }catch(error){
                console.log("error:", error)
            }
        }

        function set_data_component(data)
        {
            ComponentsLaborInformationRef.value.laborInformationModel.annual_income = data.annual_income;
            ComponentsLaborInformationRef.value.laborInformationModel.company = data.company;
            ComponentsLaborInformationRef.value.laborInformationModel.occupation = data.occupation;
        }

        function get_user_information()
        {
            const user_information = JSON.parse(sessionStorage.getItem("user-information"));
            set_data_component(
                { 
                    occupation:user_information.work_info.occupation, 
                    annual_income:user_information.work_info.annual_income,
                    company:user_information.work_info.company 
                });
        }

        function configuration_for_aditional()
        {
            set_title();
        }
        
        function set_title()
        {
            questionTitle.value += " para adicional";
        }

        const saveData = (values) => {
            const nameStorage = `issuance-${getCategory(false)}`;
            let issuance = getLocalStorage(nameStorage);
             
            issuance.work.annual_amount = values.annual_income;
            issuance.work.company_name = values.company;
            issuance.work.occupation = values.occupation;
            createLocalStorage(nameStorage, issuance)
        }

        const getCategory = (replace = true) => {
            let product = route.params.product;
            return replace? product.replaceAll("-", " "): product;
        }

        const next = async ()   => {
            const ComponentsLaborInformationRefs = ComponentsLaborInformationRef.value.execute_validation();
            if(!ComponentsLaborInformationRefs) return false ;
            createLocalStorage('issuance-labor-information', ComponentsLaborInformationRefs);
            saveData(ComponentsLaborInformationRefs);
            router.push('cobro')
        }

        const redirect_history = () => router.go(-1);

        return { 
            rulevalidations,
            view_class_view,
            isActive,
            next,
            questionTitle,
            ComponentsLaborInformationRef,
            redirect_history,
            formVisibility
        }
    },
    components:{
        Loading
    }

}
</script>
<style>
.question-container > div > div {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question-container > div > div > button{
    border-radius:5px;
    width:49%;
}

.question-container>div>label, .question-container>div>input, .question-container>div>button {
     font-family: "roboto-regular";
}

.question-container > div > div > button.active {
    background-color: #810042;
    color: white;
    border-color: #810042;
}

.question-container > div > div > button {
    box-sizing: border-box;
    background: #D9D9D9;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    margin-inline: 1px;
}

.question-container>div>label {
    font-size: 18px;
    line-height: 30px;
    color: #485462;
}

.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.content_textarea_0 {
    margin-top: 20px;
}

.content_date_3, .content_date_4 {
    margin-top: 9px;
}

.vpd-input-group input {
    flex-grow: 1;
    border: 2px solid #e5e7eb;
    border-right: 2px solid #e5e7eb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 30px;
    padding: 0 10px;
    height: 43px;
}

.content_textarea_6 {
    display:flex;
}

.content_checkbox_1 > div {
    width:100%!important;
}


@media only screen and (min-width: 600px) {
    .content_date_3, .content_date_4 {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .content_date_3, .content_date_4 {
        display: flex;
    }
}

#input_rfc_contracting_data_1,
#input_curp_contracting_data_2{
    text-transform: uppercase;
}
</style>
<style scoped>
.button-disabled {
    background-color: #EDD191;
}



@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
    .content_date_3, .content_date_4 {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    } 
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
button {
    font-size: 19px!important;
}

</style>